import React from 'react';
import ProfileComp from "./ProfileComp/ProfileComp";
import { connect } from "react-redux";

const ProfileYou = ( props ) => {
    return (
        <ProfileComp
            type='you'
            shortTerm={props.shortTermLossesYou}
            longTerm={props.longTermReturnsYou}
            recoveryPeriod={props.recoveryPeriodYou}
        />
    )
};

function mapStateToProps(state) {
    return {
        shortTermLossesYou: state.investorProfile.shortTermLossesYou,
        longTermReturnsYou: state.investorProfile.longTermReturnsYou,
        recoveryPeriodYou: state.investorProfile.recoveryPeriodYou,
    }
}

export default connect(mapStateToProps, null)(ProfileYou);