import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import ProfileYou from "../../../components/ContentRow/Profile/ProfileYou";
import ProfileSpouse from "../../../components/ContentRow/Profile/ProfileSpouse";
import { secondStepCompleted } from "../../../store/actions/investorProfileActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const Profile = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE',
        sectionTitle: 'Here’s your profile based on your answers.',
    };

    const title = "Your retirement planning profile"

    useEffect(() => {
        props.secondStepCompleted(false)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep2={(6/9)*100}
            active={'step2'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                <InnerLayoutStepContent type={'you'}>
                    <ProfileYou/>
                </InnerLayoutStepContent>

                {
                    props.isSpouse &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <ProfileSpouse/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

            <Navigation
                isCompleted={true}
                next={retirementCalcLinks.rateOfReturnYou}
            />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);