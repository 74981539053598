import React from 'react';
import classes from './ProfileComp.module.scss'
import {ReactComponent as PieBalanced} from "../../../../assets/images/profile/pie3_balanced.e22c979c.svg";
import {ReactComponent as PieGrowth} from "../../../../assets/images/profile/pie5_growth.b83e0ff9.svg";
import {ReactComponent as PieModerateGrowth} from "../../../../assets/images/profile/pie4_moderategrowth.5c891d32.svg";
import {ReactComponent as GaugeBalanced} from "../../../../assets/images/profile/2.svg";
import {ReactComponent as GaugeGrowth} from "../../../../assets/images/profile/4.svg";
import {ReactComponent as GaugeModerateGrowth} from "../../../../assets/images/profile/3.svg";
import { connect } from "react-redux";
import { setProfile } from "../../../../store/actions/investorProfileActions";

const ProfileComp = ( props ) => {
    const stylePie = {
        width: 100,
        height: 100
    };

    const styleGauge = {
        width: '100%',
        height: 'auto'
    };

    function profileDefinition() {
        let profile = 'Balanced';

        if (props.shortTerm === 'Yes' && props.longTerm === 'Yes') {
            if(props.recoveryPeriod === 1 || props.recoveryPeriod === 3) {
                profile = 'Moderate Growth'
            }else {
                profile = 'Growth'
            }
        }

        props.setProfile(props.type, profile);
        return profile
    }

    const type = profileDefinition();

    const balanced = {
        title: 'Balanced',
        equities: '50%',
        fixedIncome: '50%',
        pie: <PieBalanced style={stylePie}/>,
        gauge: <GaugeBalanced style={styleGauge}/>
    };

    const moderateGrowth = {
        title: 'Moderate Growth',
        equities: '60%',
        fixedIncome: '40%',
        pie: <PieModerateGrowth style={stylePie}/>,
        gauge: <GaugeModerateGrowth style={styleGauge}/>
    };

    const growth = {
        title: 'Growth',
        equities: '80%',
        fixedIncome: '20%',
        pie: <PieGrowth style={stylePie}/>,
        gauge: <GaugeGrowth style={styleGauge}/>
    }

    let data = balanced;

    if (type === 'Growth') {
        data = growth
    }

    if (type === 'Moderate Growth') {
        data = moderateGrowth
    }

    return (
        <div className={classes.ProfileWrap}>
            <div className={classes.ProfileInfo}>
                <figure>
                    {data.pie}
                    <figcaption>
                        <h3>{data.title}</h3>
                        <ul>
                            <li>{data.equities} equities /</li>
                            <li>{data.fixedIncome} fixed income</li>
                        </ul>
                    </figcaption>
                </figure>
            </div>
            <div className={classes.ProfileChart}>
                {data.gauge}
                <div className={classes.GaugeLabels}>
                    <span>Low Risk</span>
                    <span>High Risk</span>
                </div>
            </div>
        </div>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        setProfile: (person, val) => dispatch(setProfile(person, val))
    }
}

export default connect(null, mapDispatchToProps)(ProfileComp);