import React from 'react';
import ProfileComp from "./ProfileComp/ProfileComp";
import { connect } from "react-redux";

const ProfileSpouse = ( props ) => {
    return (
        <ProfileComp
            type='spouse'
            shortTerm={props.shortTermLossesSpouse}
            longTerm={props.longTermReturnsSpouse}
            recoveryPeriod={props.recoveryPeriodSpouse}
        />
    )
};

function mapStateToProps(state) {
    return {
        shortTermLossesSpouse: state.investorProfile.shortTermLossesSpouse,
        longTermReturnsSpouse: state.investorProfile.longTermReturnsSpouse,
        recoveryPeriodSpouse: state.investorProfile.recoveryPeriodSpouse,
    }
}

export default connect(mapStateToProps, null)(ProfileSpouse);